
    <div class="container">
      <header class="d-flex justify-content-center">
      <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="mb-3 mt-2">
            <h4 class="pt-4 mb-4 text-center">Intеrnеt tarmog`ining UZ milliy sеgmеntida joylashgan vеb-saytlarni kiberxavfsizligi talablari asosida tekshirish</h4>
              <div class="row pt-4">
                  <div class="col-12">
                    <label for="">Sayt nomi:<span class="text-danger">*</span></label>
                    <input type="text" formControlName="siteName" class="form-control w-100 border-0">
                    <div *ngIf="form.get('siteName').invalid && form.get('siteName').touched" class="validation">
                      <small> <i>Sayt nomi</i> kiritilishi shart</small>
                    </div>
                    <hr>
                  </div>
                  <!-- <div class="col-12">
                      <label for="">Tarif turini tanlang:</label>
                      <select name="select" class="form-control w-100 border-0" formControlName="type">
                        <option value="LIGHT (3.558.420 so'm / 1 ta veb sayt)" selected>LIGHT (3.558.420 so'm / 1 ta veb sayt)</option>
                        <option value="5535320">STANDART (5.535.320 so'm / 1 ta veb sayt)</option>
                      </select>
                      <hr>
                  </div> -->
              </div>
              <!-- <div class="row">
                  <div class="col-6">
                      <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-outline-primary">Hisoblash</button>
                  </div>
                  <div class="col-6">
                    <span>{{approximateAmount.toLocaleString()}} (so'm)</span>
                      <hr>
                  </div>
              </div> -->
              <!-- <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6">
                      <button type="button" class="btn btn-lg btn-outline-secondary w-100" [disabled]="!approximateAmount" (click)="addSystem()"> {{(!contractIndex ? 'Sayt qo`shish' : 'Tahrir qilish')}}</button>
                  </div>
              </div> -->
              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                <button type="button" class="btn btn-lg btn-success mt-3" (click)="next()">Keyingi</button>
              </div>
          </div>
      </form>
  </header>
  <!-- <header class="d-flex justify-content-center" *ngIf="contractList.length">
    <div  *ngIf="contractList.length">
      <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form">
        <div class="mb-3 mt-2">
          <app-calculation></app-calculation>
        </div>
      </form>
    </div>
  </header> -->
</div>
