
    <div class="container">
          <header class="d-flex justify-content-center">
          <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="mb-3 mt-2">
                <h4 class="pt-4 mb-4 text-center">Tizim ma'lumotlari</h4>
                  <div class="row pt-4">
                      <div class="col-12">
                        <label for="">Tizim nomi:<span class="text-danger">*</span></label>
                        <input type="text" formControlName="systemName" class="form-control w-100 border-0">
                        <div *ngIf="form.get('systemName').invalid && form.get('systemName').touched" class="validation">
                          <small> <i>Tizim nomi</i> kiritilishi shart</small>
                        </div>
                        <hr>
                    </div>
                      <div class="col-12">
                          <label for="">Tizim hajmi (bekend MB)<span class="text-danger">*</span></label>
                          <input type="number" formControlName="capacity" #capacity (keydown)="onKeycapacity($event)" class="form-control w-100 border-0">
                          <div *ngIf="form.get('capacity').invalid && form.get('capacity').touched" class="validation">
                            <small><i>Tizim hajmi (bekend MB)</i> kiritilishi shart</small>
                          </div>
                          <hr>
                      </div>
                      <div class="col-12">
                          <label for="">Tizimdagi rollar soni:<span class="text-danger">*</span></label>
                          <input type="number" formControlName="roleCount" #roleCount (keydown)="onKeyroleCount($event)" class="form-control w-100 border-0">
                          <div *ngIf="form.get('roleCount').invalid && form.get('roleCount').touched" class="validation">
                            <small><i>Tizimdagi rollar soni</i> kiritilishi shart</small>
                          </div>
                          <hr>
                      </div>
                      <div class="col-12">
                        <label for="">Tizimdagi modullar soni:</label>
                          <input type="number" formControlName="moduleCount" #moduleCount (keydown)="onKeymoduleCount($event)" class="form-control w-100 border-0">
                          <hr>
                      </div>
                      <div class="col-12">
                          <label for="">Ma'lumotlar bazasidagi jadvallar soni:<span class="text-danger">*</span></label>
                          <input type="number" formControlName="tableCount" #tableCount (keydown)="onKeytableCount($event)" class="form-control w-100 border-0">
                          <div *ngIf="form.get('tableCount').invalid && form.get('tableCount').touched" class="validation">
                            <small><i>Ma'lumotlar bazasidagi jadvallar soni</i> kiritilishi shart</small>
                          </div>
                          <hr>
                      </div>
                      <div class="col-12">
                          <label for="">Bu tizim bilan integratsiya qilingan boshqa tizimlar soni:</label>
                          <input type="number" formControlName="systemCount" #systemCount (keydown)="onKeysystemCount($event)" class="form-control w-100 border-0">
                          <hr>
                      </div>
                  </div>
                  <!-- <div class="row">
                      <div class="col-6">
                          <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-outline-primary">Hisoblash</button>
                      </div>
                      <div class="col-6">
                        <span>{{approximateAmount.toLocaleString()}} (so'm)*</span>
                          <hr>
                      </div>
                  </div> -->
                  <!-- <div class="row">
                      <div class="col-3"></div>
                      <div class="col-6">
                          <button type="button" class="btn btn-lg btn-outline-secondary w-100" (click)="addSystem()"> {{(!contractIndex ? 'Tizim qo`shish' : 'Tahrir qilish')}}</button>
                      </div>
                  </div> -->
                  <!-- <div class="d-flex justify-content-start">
                    <span class="mt-4">* - Bu taxminiy summa bo'lib, aniq summa shartnomada ko'rsatiladi</span>
                  </div> -->
                  <div class="d-flex justify-content-between">
                    <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                    <button type="button" class="btn btn-lg btn-success mt-3" [disabled]="form.invalid" (click)="next()">Keyingi</button>
                  </div>
              </div>
          </form>
      </header>
      <!-- <header class="d-flex justify-content-center" *ngIf="contractList.length">
        <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form">
          <div class="mb-3 mt-2">
            <app-calculation></app-calculation>
          </div>
        </form>
      </header> -->
  </div>
