import { ContractInvestComponent } from './contract/contract-invest/contract-invest.component';
import { ContractCertComponent } from './contract/contract-cert/contract-cert.component';
import { ContractAuditComponent } from './contract/contract-audit/contract-audit.component';
import { ContractAnnualComponent } from './contract/contract-annual/contract-annual.component';
import { ContractEisctrComponent } from './contract/contract-eisctr/contract-eisctr.component';
import { ContractEtrcicsrComponent } from './contract/contract-etrcicsr/contract-etrcicsr.component';
import { ContractPcsComponent } from './contract/contract-pcs/contract-pcs.component';
import { ContractDispComponent } from './contract/contract-disp/contract-disp.component';
import { ContactsComponent } from './contract/contacts/contacts.component';
import { RequisiteComponent } from './contract/requisite/requisite.component';
import { CalculationComponent } from './contract/calculation/calculation.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContractComponent } from './contract/contract.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ContractService } from './contract/contract.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContractReagComponent } from './contract/contract-reag/contract-reag.component';
import { ContractMobileComponent } from './contract/contract-mobile/contract-mobile.component';

@NgModule({
  declarations: [
    AppComponent,
    ContractComponent,
    CalculationComponent,
    RequisiteComponent,
    ContactsComponent,
    DashboardComponent,
    ContractDispComponent,
    ContractPcsComponent,
    ContractEtrcicsrComponent,
    ContractEisctrComponent,
    ContractAnnualComponent,
    ContractAuditComponent,
    ContractCertComponent,
    ContractReagComponent,
    ContractInvestComponent,
    ContractMobileComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule

  ],
  providers: [ContractService],
  bootstrap: [AppComponent]
})
export class AppModule { }
