import { Router } from '@angular/router';
import { DataShareService } from './../services/data-share.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {

  public form: FormGroup;
  public constValue: number = 197690;
  public minValue: number = 18978240;
  public approximateAmount: number = 0;
  public capacityValue: number = 0;
  public roleValue: number = 0;
  public moduleValue: number = 0;
  public tableValue: number = 0;
  public systemValue: number = 0;
  public comparedValue: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any
  @ViewChild('capacity') capacity: ElementRef
  @ViewChild('roleCount') roleCount: ElementRef
  @ViewChild('moduleCount') moduleCount: ElementRef
  @ViewChild('tableCount') tableCount: ElementRef
  @ViewChild('systemCount') systemCount: ElementRef


  constructor( private dataShareService: DataShareService, private router: Router ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        systemName: new FormControl(null, Validators.required),
        capacity: new FormControl(null, Validators.required),
        roleCount: new FormControl(null, Validators.required),
        moduleCount: new FormControl(null, []),
        tableCount: new FormControl(null, Validators.required),
        systemCount: new FormControl(null, [])
      }
    )
    if(localStorage.getItem('contract')) {
      const formData = JSON.parse(localStorage.getItem('contract'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })
  }

  onSubmit() {
    if(this.form.value.capacity) {
      // Calculate capacity
      if(this.form.value.capacity*0.2 < 8) {
        this.capacityValue = 8;
      } else if(this.form.value.capacity*0.2 > 100) {
        this.capacityValue = 100;
      } else {
        this.capacityValue = Math.round(0.2*this.form.value.capacity)
      }
    }
    /// Calculate Role
    if(this.form.value.roleCount) {
      if(this.form.value.roleCount > 8) {
        this.roleValue = 192;
      } else {
        this.roleValue = this.form.value.roleCount*24
      }
    }
    /// Calculate Module
    if(this.form.value.moduleCount) {
      if(this.form.value.moduleCount > 10) {
        this.moduleValue = 160;
      } else {
        this.moduleValue = this.form.value.moduleCount*16;
      }
    }
    /// Compare module and role values
    if(this.roleValue > this.moduleValue) {
      this.comparedValue = this.roleValue;
    } else {
      this.comparedValue = this.moduleValue;
    }
    /// Calculate table
    if(this.form.value.tableCount) {
      if(this.form.value.tableCount > 240) {
        this.tableValue = 64;
      } else {
        this.tableValue = Math.round(this.form.value.tableCount*0.27)
      }
    }
    /// Calculate system
    if(this.form.value.systemCount) {
      if(this.form.value.systemCount) {
        this.systemValue = this.form.value.systemCount*16;
      }
    }
    this.approximateAmount = (this.capacityValue+this.comparedValue+this.tableValue+this.systemValue)*this.constValue;
    if(this.approximateAmount < this.minValue) {
      this.approximateAmount = this.minValue;
    } else {
      this.approximateAmount = this.approximateAmount;
    }
    this.form.value['sum']=this.approximateAmount
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
  }

  addSystem() {
    localStorage.setItem('title', "Axborot tizimini axborot xavfsizligi talablariga muvofqligi yuzasidan ekspertizadan o'tkazish")
    localStorage.setItem('contract', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'monitoring')
    localStorage.setItem('columnsMain', JSON.stringify( [
      'Tizim nomi',
      'Tizim hajmi',
      'Rollar soni',
      'Modullar soni',
      'Ma`lumotlar bazasidagi jadvallar soni',
      'Bu tizim bilan integratsiya qilingan boshqa tizimlar soni',
    ]))
    localStorage.setItem('columnsTemp', JSON.stringify([
      'systemName',
      'capacity',
      'roleCount',
      'moduleCount',
      'tableCount',
      'systemCount'
  ]))
    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      this.form.reset()
      this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      this.form.reset()
      this.approximateAmount = 0
    }

    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  showContract(index) {
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    localStorage.setItem('title', "Axborot tizimini axborot xavfsizligi talablariga muvofqligi yuzasidan ekspertizadan o'tkazish")
    localStorage.setItem('contract', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'monitoring')
    localStorage.setItem('columnsMain', JSON.stringify( [
      'Tizim nomi',
      'Tizim hajmi',
      'Rollar soni',
      'Modullar soni',
      'Ma`lumotlar bazasidagi jadvallar soni',
      'Bu tizim bilan integratsiya qilingan boshqa tizimlar soni',
    ]))
    localStorage.setItem('columnsTemp', JSON.stringify([
      'systemName',
      'capacity',
      'roleCount',
      'moduleCount',
      'tableCount',
      'systemCount'
  ]))
    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      this.form.reset()
      this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      this.form.reset()
      this.approximateAmount = 0
    }

    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    this.router.navigate(['/contacts'])
  }

  back() {
    this.router.navigate(['/'])
  }

 // SET LIMIT
 onKeycapacity(e: any) {
    const valueCapacity = this.capacity.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenCapacity = valueCapacity.length

    if(lenCapacity >= 4) {
      e.preventDefault()
    }
  }

  onKeyroleCount(e: any) {
    const valueRole = this.roleCount.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenRole = valueRole.length

    if(lenRole >= 2) {
      e.preventDefault()
    }
  }

  onKeymoduleCount(e: any) {
    const valueModule = this.moduleCount.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenModule = valueModule.length

    if(lenModule >= 2) {
      e.preventDefault()
    }
  }

  onKeytableCount(e: any) {
    const valueTable = this.tableCount.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenTable = valueTable.length

    if(lenTable >= 3) {
      e.preventDefault()
    }
  }

  onKeysystemCount(e: any) {
    const valueSystem = this.systemCount.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenSystem = valueSystem.length

    if(lenSystem >= 2) {
      e.preventDefault()
    }
  }

}
