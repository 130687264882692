import { DataShareService } from './../services/data-share.service';
import { Component, OnInit } from '@angular/core';

export interface Services {
  id: number;
  name: string;
  icon: string;
  url: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public serviceNames: Services[] = [];;

  constructor( private dataShareService: DataShareService) { }

  ngOnInit() {
    this.serviceNames = data;
    localStorage.removeItem('contract')
    localStorage.removeItem('contract-disp')
    localStorage.removeItem('contract-pcs')
    localStorage.removeItem('contract-etrcicsr')
    localStorage.removeItem('contract-eisctr')
    localStorage.removeItem('contract-annual')
    localStorage.removeItem('contract-audit')
    localStorage.removeItem('contract-reag')
    localStorage.removeItem('contract-invest')
    this.dataShareService.nextAccounts(null)
  }

}

const data = [
  {
    id: 1,
    name: "Texnik topshiriqlarni axborot va kiberxavfsizlik talablariga muvofiqligi bo'yicha ekspertizadan o'tkazish",
    icon: "/assets/images/privacy-policy.png",
    url: "/contract-etrcicsr"
  },
  {
    id: 2,
    name: "Axborot tizimlarini texnik topshiriq talablariga muvofiqligi bo'yicha ekspertizadan o'tkazish",
    icon: "/assets/images/compliant.png",
    url: "/contract-eisctr"
  },
  {
    id: 3,
    name: "Axborot tizimini axborot xavfsizligi talablariga muvofqligi yuzasidan ekspertizadan o'tkazish",
    icon: "/assets/images/insurance-policy-same.png",
    url: "/contract"
  },
  {
    id: 4,
    name: "Axborot xavfsizlik siyosatini ishlab chiqish",
    icon: "/assets/images/insurance-policy.png",
    url: "/contract-disp"
  },
  {
    id: 5,
    name: "Konsalting xizmatlarni ko'rsatish",
    icon: "/assets/images/policy.png",
    url: "/contract-pcs"
  },
  {
    id: 6,
    name: "Konsalting xizmatlarni ko'rsatish (yillik paketlar)",
    icon: "/assets/images/policy.png",
    url: "/contract-annual"
  },
  {
    id: 7,
    name: "Axborotlashtirish obyektlarining axborot xavfsizligi auditi",
    icon: "/assets/images/insurance.png",
    url: "/contract-audit"
  },
  {
    id: 8,
    name: "Sertifikatlashtirish bo'yicha organ xizmatlari",
    icon: "/assets/images/compliant.png",
    url: "/contract-cert"
  },
  {
    id: 9,
    name: "Intеrnеt tarmog`ining UZ milliy sеgmеntida joylashgan vеb-saytlarni kiberxavfsizligi talablari asosida tekshirish",
    icon: "/assets/images/compliant.png",
    url: "/contract-reag"
  },
  {
    id: 10,
    name: "Elektron saqlash vositalaridan raqamli ma'lumotlarni qayta tiklash va yo'q qilish bo'yicha xizmatlar",
    icon: "/assets/images/compliant.png",
    url: "/contract-invest"
  },
  {
    id: 11,
    name: "Mobil ilovani axborot xavfsizligi talablariga muvofiqligi yuzasidan ekspertizadan o'tkazish",
    icon: "/assets/images/mobile.png",
    url: "/contract-mobile"
  }
]
